.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #50f;
  min-height: 0vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(0, 21, 255);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.btn-submit-dark-small {
  background-color: #5500ff;
  color: #fff;
}

.btn-submit-dark-small:hover {
  background-color: #5500ff90;
  color: #fff;
}

.btn-submit-light-small {
  background-color: rgb(255, 255, 255);
  color: blue;
}

.btn-submit-light-small:hover {
  background-color: rgb(104, 104, 238);
  color: white;
}


.btn-submit-light-large {
  background-color: blueviolet;
  color: white;
  font-size: 20px;
}

.btn-submit-light-large:hover {
  background-color: rgb(104, 104, 238);
  color: white;
}

.btn-submit-light-small-outline {
  background-color: rgb(255, 255, 255);
  border: 1px solid blue;
  color: blue;
}

.btn-submit-light-small-outline:hover {
  background-color: #5500ff;
  border: 1px solid #5500ff;
  color: rgb(255, 255, 255);
}


.container {
  background-color: hsl(0, 0%, 100%);
  border-radius: 16px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  margin-left: 3%;
  margin-right: 3%;
  width: fit-content;
}

.container-pcd {
  background-color: #ffffff;
  border-radius: 16px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  margin-left: 3%;
  margin-right: 3%;
  width: fit-content;
}

.checkbox-custom {
  scale: 1.2;
  background-color: white;
  border-color: rgba(0, 0, 255, 0.704);
}

.checkbox-custom:checked {
  background-color: blue;
  border-color: rgba(0, 0, 255, 0.704);
}
/* 
.fonts {
  font-family: 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif
}

.fonts-2 {
  font-family: 'Courier New', Courier, monospace;
  font-weight: 600;
} */

.side-options {
  background-color: #ffffff;
  border-radius: 10;
}

.side-options:hover {
  background-color: #b6b6b6;
}

.material-card {
  background-color: #fff;
  border-radius: 8px;
}

.material-card:hover {
  opacity: 85%;
}


.custom-input::placeholder {
  color: white; /* Change placeholder color to white */
}